import 'typeface-roboto/index.css';
import 'typeface-playfair-display/index.css';
import 'typeface-montserrat/index.css';
import 'typeface-open-sans/index.css';
import 'typeface-oswald/index.css';
import 'typeface-lobster/index.css';
import 'typeface-anton/index.css';
import 'typeface-lilita-one/index.css';
import 'typeface-indie-flower/index.css';
import 'typeface-dancing-script/index.css';
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/v4-shims.css";
import 'whatwg-fetch';
import Prism from 'prismjs';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import '../custom';
import '../custom.css';
import '../color.css';
import '../responsive.css';
import '../no-css.css';


document.addEventListener('DOMContentLoaded', () => {
    Prism.highlightAll();
});

